import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classNames from 'classnames'
import { Helmet } from 'react-helmet'

import slugify from 'helpers/slugify'
import scrollTo from 'helpers/scrollTo'

import Layout from 'components/Layout'
import SEO from 'components/SEO'
import SidebarMenu from 'components/SidebarMenu'

import s from './styles.module.css'

const Legal = ({ data }) => {
  // const [toggleMenu, setToggleMenu] = useState(false)

  const handleScrollTo = (e, target) => {
    e.preventDefault()
    scrollTo(0, { target: `legal-${target}` })
  }

  const {
    seo: { title: seoTitle, description: seoDescription },
    title,
    acfLegal: { description, sections },
  } = data.wordpressLegal

  return (
    <Layout backgroundColor="#F2F2F2">
      <Helmet>
        <html data-theme-color="ebb" />
      </Helmet>
      <SEO
        title={seoTitle.replace('&#038;', 'and')}
        description={seoDescription}
      />
      <div className={s.container}>
        <SidebarMenu activeLink={title} childLinks={sections} />
        <div className={s.content}>
          <h1 className={s.title} dangerouslySetInnerHTML={{ __html: title }} />
          {description && (
            <div
              className={s.description}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
          {sections && (
            <div className={s.sections}>
              {/* <button
                type="button"
                className={s.toggleMenu}
                onClick={() => setToggleMenu(!toggleMenu)}
              >
                {toggleMenu ? (
                  <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.707 1.293a1 1 0 011.414 0l9.9 9.9a1 1 0 01-1.414 1.414l-9.9-9.9a1 1 0 010-1.414z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.343 12.707a1 1 0 010-1.414l9.9-9.9a1 1 0 111.414 1.415l-9.9 9.9a1 1 0 01-1.414 0z"
                    />
                  </svg>
                ) : (
                  <svg viewBox="0 0 12 10" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 1.75L12 1.75L12 0.250001L4 0.25L4 1.75Z" />
                    <path d="M-1.31134e-07 1.75L2 1.75L2 0.25L1.31134e-07 0.25L-1.31134e-07 1.75Z" />
                    <path d="M4 5.75L12 5.75L12 4.25L4 4.25L4 5.75Z" />
                    <path d="M-1.31134e-07 5.75L2 5.75L2 4.25L1.31134e-07 4.25L-1.31134e-07 5.75Z" />
                    <path d="M4 9.75L12 9.75L12 8.25L4 8.25L4 9.75Z" />
                    <path d="M-1.31134e-07 9.75L2 9.75L2 8.25L1.31134e-07 8.25L-1.31134e-07 9.75Z" />
                  </svg>
                )}
              </button> */}
              <div
                className={classNames(s.menu, {
                  // [s.menuOpen]: toggleMenu,
                })}
              >
                <h2 className={s.heading}>Index</h2>
                <div className={s.menuItems}>
                  {sections.map(({ heading }, i) => (
                    <a
                      key={`${heading}${i}`}
                      href={`#legal-${slugify(heading)}`}
                      className={s.menuBtn}
                      onClick={(e) => handleScrollTo(e, slugify(heading))}
                      dangerouslySetInnerHTML={{ __html: heading }}
                    />
                  ))}
                </div>
              </div>
              {sections.map(({ heading, content }) => (
                <div
                  key={heading}
                  id={`legal-${slugify(heading)}`}
                  className={s.sectionContainer}
                >
                  <h2
                    className={s.heading}
                    dangerouslySetInnerHTML={{ __html: heading }}
                  />
                  <div className={s.sectionWrap}>
                    <div
                      className={s.section}
                      dangerouslySetInnerHTML={{ __html: content }}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Legal

Legal.propTypes = {
  data: PropTypes.shape({
    wordpressLegal: PropTypes.shape({
      seo: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
      }),
      title: PropTypes.string,
      acfLegal: PropTypes.shape({
        description: PropTypes.string,
        sections: PropTypes.arrayOf(
          PropTypes.shape({
            heading: PropTypes.string,
            content: PropTypes.string,
          })
        ),
      }),
    }),
  }),
}

export const pageQuery = graphql`
  query LegalQuery($id: String!) {
    wordpressLegal(id: { eq: $id }) {
      seo {
        title
        description
      }
      title
      acfLegal {
        description
        sections {
          heading
          content
        }
      }
    }
  }
`
